/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from "react";

import {
	Autocomplete,
	AutocompleteRenderInputParams,
	CircularProgress,
	InputLabel,
	Stack,
	TextField,
	useTheme,
} from "@mui/material";
import { GoogleMapsApi, GooglePlace } from "nowr-api-main-manager/dist";
import { useApi } from "../../utils/api";
import MLDialog from "../MLDialog";

interface ISuggestionWithCoordinates {
	[key: string]: any;
	description: string;
	placeId: string;
	coordinates: {
		lat: number;
		lng: number;
	};
}

const SearchPlaceAutocomplete = (props: {
	label?: string;
	placeholder?: string;
	onCancel?: () => void;
	onGeocoded?: (suggestionWithCoordinates: ISuggestionWithCoordinates) => void;
	initialValue?: string;
	renderInput?: (params: AutocompleteRenderInputParams, loading: boolean) => React.ReactNode;
}) => {
	const theme = useTheme();

	const gmapsApi = useApi(GoogleMapsApi);

	const [inputValue, setInputValue] = useState(props.initialValue || "");
	const lastInputValue = useRef(inputValue);

	useEffect(() => {
		if (props.initialValue) {
			setInputValue(props.initialValue);
		}
	}, [props.initialValue]);

	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState<GooglePlace[]>([]);
	const [loading, setLoading] = useState(false);

	const getSuggestionFromQuery = useCallback(async (query: string) => {
		if (!query) {
			return [];
		}
		setLoading(true);
		gmapsApi
			.getSuggestionFromGoogleMaps(query)
			.then((res) => {
				setOptions(res.data);
			})
			.catch((err) => MLDialog.showSnackbar("Qualcosa è andato storto", { variant: "error" }))
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSuggestion = useCallback(
		(newSuggestion: GooglePlace) => {
			setInputValue(newSuggestion?.description || "");
			lastInputValue.current = newSuggestion?.description || "";

			gmapsApi.getGeocodeFromPlaceId(newSuggestion.place_id).then((res) => {
				const { geometry } = res.data || {};
				const coordinates = {
					lat: geometry?.location?.lat || 0,
					lng: geometry?.location?.lng || 0,
				};
				//@ts-ignore
				const suggestionWithCoordinates: ISuggestionWithCoordinates = {
					...newSuggestion,
					placeId: newSuggestion?.place_id || "",
					coordinates,
				};
				props.onGeocoded?.(suggestionWithCoordinates);
			});
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.onGeocoded]
	);

	return (
		<Autocomplete
			id="gmaps-place"
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOptions([]);
				setOpen(false);
			}}
			filterOptions={(x) => x}
			getOptionLabel={(option) => option.description || ""}
			isOptionEqualToValue={(option, value) => option.description === value.description}
			options={options}
			loading={loading}
			inputValue={inputValue}
			onInputChange={(event, newInputValue, reason) => {
				getSuggestionFromQuery(newInputValue);
				if (reason != "reset") setInputValue(newInputValue);

				if (newInputValue?.length === 0) {
					props?.onCancel?.();
					lastInputValue.current = "";
				}
			}}
			onChange={(e, newValue) => {
				if (newValue) onSuggestion(newValue || {});
			}}
			loadingText="Ricerca..."
			noOptionsText="Nessun risultato"
			renderInput={
				props.renderInput
					? (params) => props.renderInput?.(params, loading)
					: (params) => (
							<Stack>
								{props?.label && (
									<InputLabel
										shrink={true}
										sx={{
											color: theme.palette.text.secondary,
											fontSize: 17,
											whiteSpace: "nowrap",
											transform: "translate(0px, 0px) scale(0.9);",
											marginBottom: 0,
											"&.Mui-focused": {
												color: theme.palette.text.secondary,
											},
										}}
									>
										{props.label}
									</InputLabel>
								)}
								<TextField
									{...params}
									placeholder={props.placeholder}
									size="medium"
									InputProps={{
										...params.InputProps,
										sx: { height: 60 },
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="primary" size={20} sx={{ marginRight: 2 }} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							</Stack>
					  )
			}
		/>
	);
	{
	}
};

export default SearchPlaceAutocomplete;
